import {
  Alert,
  Box,
  Button,
  Center,
  Group,
  rem,
  TextInput,
} from "@mantine/core";
import { isEmail, useForm } from "@mantine/form";
import { json, LoaderFunction, redirect } from "@remix-run/node";
import { Form, useLoaderData } from "@remix-run/react";
import { IconAt } from "@tabler/icons-react";
import { useState } from "react";
import { getUser } from "~/lib/auth.server";
import { useSendOTPMutation } from "~/queries/auth";
import { Handle } from "~/types/Handle";

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const returnTo = url.searchParams.get("return_to") || "/";

  const user = await getUser(request);
  if (user) {
    return redirect(returnTo);
  }

  return json({ returnTo });
};

export default () => {
  const { returnTo } = useLoaderData<typeof loader>();
  const sendOTP = useSendOTPMutation();
  const [confirmationSent, setConfirmationSent] = useState(false);

  const form = useForm({
    initialValues: { email: "" },

    // functions will be used to validate values at corresponding key
    validate: {
      email: isEmail("Invalid email"),
    },
  });

  const onSubmit = async (values: typeof form.values) => {
    const emailRedirectTo = [
      window.location.protocol,
      "//",
      window.location.host,
      returnTo,
    ].join("");

    sendOTP.mutate(
      { email: values.email, emailRedirectTo },
      { onSuccess: () => setConfirmationSent(true) },
    );
  };

  return (
    <Center>
      <Box pos="relative" mt="xl">
        {!confirmationSent ? (
          <Form onSubmit={form.onSubmit(onSubmit)}>
            <Group mb="md">
              <TextInput
                type="email"
                placeholder="Your email"
                {...form.getInputProps("email")}
                leftSection={
                  <IconAt
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={1.5}
                  />
                }
              />
              <Button type="submit" loading={sendOTP.isPending}>
                Submit
              </Button>
            </Group>
          </Form>
        ) : (
          <>Confirmation email sent.</>
        )}
      </Box>

      {sendOTP.isError && (
        <Alert color="red" mt="md" title="Something went wrong">
          {sendOTP.error.message}
        </Alert>
      )}
    </Center>
  );
};

export const handle: Handle = {
  breadcrumb: "Login",
};
